.export-csv {
  display: inline-block;
  position: relative;
}

.export-csv__btn {
  display: block;
  width: fit-content;
  margin: 0 0 0 auto;
  outline: none;
  border: none;
  background-color: transparent;
  color: #3f3cca;
  text-align: left;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.export-csv__btn__text {
  display: inline-block;
  color: #3f3cca;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: inherit;
  text-transform: inherit;

  @media (min-width: 576px) {
    letter-spacing: 0.7px;
    line-height: 1;
  }
}

.export-csv__list {
  z-index: 2;
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 155px;
  min-height: 77px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #3f3cca;
  list-style-type: none;

  &--visible {
    display: grid;
  }

  @media (min-width: 1025px) {
    top: calc(100% + 4px);
    width: 138px;
    min-height: 67px;
    padding: 4px 10px;
  }
}

.export-csv__list-item {
  display: grid;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 2.25;
  color: #f7f7f7;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;

  &:hover {
    color: #cfdeff;
  }

  &:active {
    color: #3f3cca;
  }

  &:last-of-type {
    border: none;
  }

  &--disabled {
    cursor: not-allowed;
  }

  @media (min-width: 1025px) {
    font-size: 14px;
    line-height: 2;
  }
}
