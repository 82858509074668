.create-and-add-to-watch-list {
  width: 100%;
  padding: 28px 50px 50px;
  background-color: #5c57ff;
}

.create-and-add-to-watch-list__text {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.create-and-add-to-watch-list__input {
  width: 100%;
  height: 42px;
  border-radius: unset;
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #cfdeff;
  padding: 0 10px;
  font-size: 16px;
  color: #fff;
  margin-top: 15px;

  &:first-of-type {
    margin-top: 17px;
  }

  &::placeholder {
    color: #cfdeff;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
}

.create-and-add-to-watch-list__button {
  width: 100%;
  border: unset;
  margin-top: 25px;

  &:hover {
    border: unset;
  }

  &:active {
    border: unset;
  }

  &:disabled {
    border: unset;
  }
}
