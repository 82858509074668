.vehicle {
  min-height: 60px;
  display: grid;
  grid-template-columns: 880fr 360fr;
  background-color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 1920px) {
    grid-template-columns: 1160fr 460fr;
  }

  &--delisted {
    opacity: 0.5;
  }
}

.vehicle__link {
  display: grid;
  grid-template-columns: 150px 180fr 112fr 162fr 106fr 170fr;
  text-decoration: none;

  @media (min-width: 1920px) {
    grid-template-columns: 150px 306fr 112fr 242fr 136fr 214fr;
  }
}

.vehicle__inputs {
  display: grid;
  grid-template-columns: 190fr 170fr;

  @media (min-width: 1920px) {
    grid-template-columns: 230fr 230fr;
  }
}

.vehicle__images-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  column-gap: 20px;
  position: relative;
}

.vehicle__source-wrapper {
  width: 30px;
  height: 30px;
}
.vehicle__source-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vehicle__thumbnail {
  width: 60px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.vehicle__thumbnail-placeholder {
  width: 60px;
  height: 40px;
  background-color: #e0e0e0;
  background-image: url(/assets/images/logo-white-placeholder.svg);
  background-size: 60px 40px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.vehicle__wip-icon {
  width: 26px;
  height: 26px;
  object-fit: contain;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.vehicle__cell {
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 12px 5px;

  &--source {
    padding: 0;
  }

  &--ymmt {
    padding: 12px 0;
    justify-items: start;
  }

  &--vin {
    padding: 12px 0;
  }

  &--input {
    padding: 0;
    cursor: default;
  }

  &--delisted {
    display: grid;
    grid-template-columns: 1fr 68px;
    column-gap: 10px;
    padding-right: 20px;

    @media (min-width: 1920px) {
      padding-right: 10px;
    }
  }
}

.vehicle__text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.35px;
  white-space: normal;

  &--bold {
    font-weight: bold;
  }

  &--ymmt {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  &--vin {
    word-break: break-word;
  }
}

.vehicle__delisted-badge {
  width: 100%;
  height: 100%;
  color: #fd0100;
  border-radius: 5px;
}

.vehicle__delisted {
  width: 68px;
  height: 26px;
  align-self: start;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  background-color: #e0e0e0;
}

.vehicle__price-input {
  width: 130px;
  height: 26px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;

  @media (min-width: 1920px) {
    width: 150px;
  }
}
