.display-convos-select {
  width: 145px;
  display: grid;
  row-gap: 3px;
}

.display-convos-select__label {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #000000;
}
