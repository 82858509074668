.conversation {
  display: grid;
  width: 100%;
  padding: 21px 5px 10px 10px;
  position: relative;
  border-radius: 10px;
  text-decoration: none;
  background-color: #fff;

  &--unread {
    background-color: #f0f0f0;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(185, 182, 182, 0.8);
    z-index: 3;
  }

  @media (min-width: 576px) {
    padding: 14px 10px 13px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 16px 1fr;
    grid-column-gap: 20px;
    padding: 14px 30px 13px;
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }

  @media (min-width: 1280px) {
    padding: 11px 30px 10px;
  }

  &--media-last-message {
    @media (min-width: 576px) {
      grid-template-rows: minmax(38px, auto) minmax(24px, auto) 80px minmax(
          24px,
          auto
        );
      grid-row-gap: 7px;
      align-items: start;
    }

    @media (min-width: 1024px) {
      grid-template-rows: unset;
      grid-row-gap: 22px;
      align-items: center;
      padding: 5px 30px;
    }
    //detect Last Message block
    .conversation__label:nth-child(6) {
      @media (min-width: 576px) {
        align-self: center;
      }

      @media (min-width: 1024px) {
        align-self: unset;
      }
    }
  }
}

.conversation__non-clickable-side {
  display: none;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 16px;
    align-items: start;
  }

  @media (min-width: 1280px) {
    align-items: center;
  }
}

.conversation__clickable-side {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 9px;

  &--link {
    cursor: pointer;
  }

  @media (min-width: 576px) {
    grid-template-columns: 62px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 22px;
    align-items: center;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 140fr 265fr 277fr 180px;
    grid-column-gap: 20px;
    grid-row-gap: 0;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 210fr 287fr 453fr 173fr;
  }
}

.conversation__checkbox-wrapper {
  display: none;

  @media (min-width: 1024px) {
    width: 100%;
    height: 24px;
    display: grid;
    align-items: center;
  }
}

.conversation__checkbox-label {
  @media (min-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.conversation__value {
  font-size: 16px;
  line-height: 1.5;

  &--bold {
    font-weight: bold;
  }
}

.conversation__value-contact {
  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  @media (min-width: 1024px) {
    align-self: start;
  }

  @media (min-width: 1280px) {
    align-self: unset;
  }

  &--media-last-message {
    @media (min-width: 1024px) {
      padding: 10px 0 0;
    }

    @media (min-width: 1280px) {
      padding: 0;
    }
  }
}

.conversation__value-date {
  position: absolute;
  top: 7px;
  right: 5px;
  font-size: 10px;
  color: #7f7f7f;

  @media (min-width: 576px) {
    display: none;
  }
}

.conversation__value-car {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 10px;

  @media (min-width: 1024px) {
    align-self: start;
  }

  @media (min-width: 1280px) {
    padding-right: 5px;
    grid-column-gap: 35px;
    align-self: unset;
  }
}

.conversation__label {
  display: none;

  @media (min-width: 576px) {
    display: block;
    line-height: 1.5;
    color: #7f7f7f;
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.value-car__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  line-height: 1.5;

  &--bold {
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    white-space: normal;
    align-items: center;
  }
  @media (min-width: 1280px) {
    white-space: nowrap;
  }

  &--media-last-message {
    @media (min-width: 1024px) {
      padding: 10px 0 0;
    }

    @media (min-width: 1280px) {
      padding: 0;
    }
  }
}

.value-car__badge {
  height: max-content;
  padding: 4px 9px 5px;
  border-radius: 25px;
  font-size: 10px;
  line-height: 1.3;
  font-weight: bold;

  &--deleted {
    color: #fff;
    background-color: #fe7f7f;
    border: solid 1px #fd7f7f;
  }

  &--delisted {
    color: #fd7f7f;
    border: solid 1px #fd7f7f;
  }

  @media (min-width: 1024px) {
    margin-top: 1px;
  }
}

.conversation__value-last-message {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
}

.conversation__value-last-message__icon-source {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: cover;

  &--rounded {
    border-radius: 50%;
  }
}

.conversation__value-last-message__text {
  grid-column: 2px;
  font-style: italic;
  line-height: 1.5;
  padding-right: 13px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--bold {
    font-weight: bold;
  }

  @media (min-width: 576px) {
    padding-right: 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset;
  }

  @media (min-width: 1280px) {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.conversation__value-last-image {
  width: 120px;
  height: 80px;
  border-radius: 5px;
}

.conversation__message-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.conversation__value-date-time {
  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  @media (min-width: 1024px) {
    text-align: right;
  }
}

.conversation__value-date-time-time {
  white-space: nowrap;
}
