.message {
  width: 100%;
  display: grid;
  row-gap: 5px;
}

.message__text-wrapper {
  width: fit-content;
  max-width: 75%;
  height: max-content;
  justify-self: start;
  display: grid;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: start;

  &--user {
    justify-self: end;
    background-color: #e7efff;
    text-align: end;
  }
}

.message__text-content {
  position: relative;
  word-break: break-word;
  display: grid;
  row-gap: 2px;
}

.message__date {
  display: inline-block;
  font-size: 10px;
  line-height: 1.3;
  text-transform: uppercase;
  color: #7f7f7f;

  &--media {
    justify-self: start;
  }
}

.message__text {
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  white-space: pre-wrap;
}
