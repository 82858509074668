.maxLabel,
.minLabel {
  display: none;
}

.track {
  background: #3f51b5;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 2px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.sliderContainer {
  transition: left 0.3s ease-out;
  position: absolute;
}

.slider {
  appearance: none;
  background-color: #3f3cca;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 17px;
  width: 17px;
  margin-top: -8px;
  margin-left: -8px;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.labelContainer {
  display: inline-block;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 1.5;
}

.valueLabel {
  position: absolute;
  top: -30px;
}
