$primary-color: #fff;
$secondary-color: #f0f0f0;

.tabs {
  width: 100%;
  height: 46px;
  display: grid;
}

.tabs__tab {
  background-color: $primary-color;

  &--active {
    background-color: $secondary-color;
  }
}

.tabs__content {
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  background-color: $secondary-color;
  cursor: pointer;

  &--active {
    background-color: $primary-color;
    border-radius: 20px 20px 0 0;
    cursor: default;
  }

  &--first {
    border-radius: 0 20px 0 0;
  }

  &--last {
    border-radius: 20px 0 0 0;
  }

  &--prev {
    border-radius: 0 0 20px 0;
  }

  &--next {
    border-radius: 0 0 0 20px;
  }

  &--single {
    border-radius: 0;
  }
}

.tabs__text {
  position: relative;

  &--unread {
    &::after {
      content: "";
      position: absolute;
      top: -3px;
      right: -5px;
      width: 8px;
      height: 8px;
      background-color: #fd0100;
      border-radius: 50%;
    }
  }
}
