.vehicle-card {
  width: 100%;
  max-width: 385px;
  margin: auto;
  height: 100%;
  display: grid;
  grid-template-rows: 200px auto;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.vehicle-card__photo {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.vehicle-card__placeholder {
  background-color: #e0e0e0;
  background-image: url(../../../assets/images/logo-white-placeholder.svg);
  background-size: 120px 118px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px 5px 0 0;
}

.vehicle-card__description-wrapper {
  width: 100%;
  height: auto;
  min-height: 100px;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  padding: 14px 15px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
}

.vehicle-card__description {
  height: 100%;
  display: grid;

  &--left-side {
    grid-template-rows: max-content 1fr max-content;
  }

  &--right-side {
    width: max-content;
    grid-template-rows: repeat(2, 1fr);
  }
}

.vehicle-card__text {
  font-size: 12px;
  line-height: 1.5;
  word-break: break-word;

  &--bold {
    font-weight: bold;
  }

  &--left-side {
    letter-spacing: -0.35px;
  }

  &--right-side {
    text-align: right;
  }

  &--price {
    font-size: 16px;
    line-height: 1.31;
    color: #36dec5;
    align-self: end;
    margin-bottom: 2px;
  }
}

.vehicle-card__distance {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 5px;
  justify-content: end;
}

.vehicle-card__icon-map-point {
  margin-top: 1px;
}

.vehicle-card__delisted {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(247, 247, 247, 0.5);
  border-radius: 5px;
}

.vehicle-card__badge {
  width: 68px;
  height: 26px;
  margin-top: 164px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #fd0100;
  background-color: #e0e0e0;
  display: grid;
  align-content: center;
  justify-content: center;
}

.vehicle-card__source-wrapper {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  top: 10px;
  left: 10px;
}

.vehicle-card__wip-badge-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 49px;
  height: 49px;
  border-radius: 5px;
}

.vehicle-card__source {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vehicle-card__wip-badge {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vehicle-card__trash-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
