$breakpoint: 744px;

.pagination {
  display: none;
  width: fit-content;
  margin: auto;
  margin-top: 16px;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 50px;

  @media (min-width: $breakpoint) {
    display: grid;
  }
}

.pagination__ui-controls {
  display: grid;
  grid-template-columns: 12px max-content 12px;
  grid-column-gap: 20px;
  align-content: center;
  align-items: center;
}

.pagination__icon {
  width: 12px;
  height: 12px;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }
}

.pagination__buttons {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 10px;
}

.pagination__button {
  width: fit-content;
  height: 40px;
  background-color: #ffce00;
  font-size: 14px;
  letter-spacing: normal;
  color: #1c1c1c;
  text-transform: unset;
  padding: 0 20px;
  display: inline-grid;
  grid-column-gap: 10px;
  align-content: center;
  grid-template-columns: repeat(2, max-content);

  &--mobile-unfilled {
    width: 40px;
    background-color: transparent;
    padding: 0 14px;
    border: 2px solid #fece00;
    grid-column-gap: unset;
    align-items: center;
    grid-template-columns: unset;

    &:active,
    &:disabled {
      background-color: transparent !important;
    }
  }

  &:hover {
    box-shadow: unset;
  }

  &:active {
    background-color: #ffce00;
    color: #1c1c1c;
  }

  &:disabled {
    background-color: #fbe27b;
  }
}

.pagination__button-icon {
  width: 6px;
  height: 15px;
}

.pagination__input-wrapper {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 10px;
  align-items: center;
}

.pagination__input {
  width: 44px;
  height: 40px;
  border-radius: unset;
  border: unset;
  padding: 0 10px;
  font-size: 14px;
  text-align: center;
}

.pagination__text {
  font-size: 14px;
}

.mobile-pagination {
  display: grid;
  width: fit-content;
  margin: auto;
  margin-top: 16px;
  grid-auto-flow: column;
  grid-column-gap: 20px;

  @media (min-width: $breakpoint) {
    display: none;
  }
}
