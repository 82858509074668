.labled-input__label {
  font-size: 13px;
  line-height: 1.62;
  color: #7f7f7f;
  margin-bottom: 4px;

  &--required {
    position: relative;
    top: 5px;
    color: #fd0100;
  }
}

.labled-input__input {
  padding-left: 13px;
  width: 100%;
  height: 43px;
  font-size: 13px;
  border: solid 1px #d2defc;
  line-height: 1.62;
  outline: none;
  color: #1f1f1f;

  &::placeholder {
    color: #1f1f1f;
  }

  &--error {
    border: solid 1px #fd0100;
  }
}

.labled-input__error {
  font-size: 11px;
  text-align: center;
  line-height: 1.91;
  color: #fd3939;
}
