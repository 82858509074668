.comment-wrapper {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;
  transition: opacity 2s;

  &--opacity {
    opacity: 0;
  }

  &--delete {
    display: none;
  }
}

.comment-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.comment {
  position: relative;
  word-break: break-word;
}

.comment__textarea {
  width: 100%;
  height: 40px;
  padding: 11px 55px 11px 14px;
  outline: 0;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  color: #1c1c1c;
  word-break: break-word;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: #878787;
  }
}

.comment__icons {
  position: absolute;
  top: 12px;
  right: 10px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 10px;
}

.comment__icon {
  height: 16px;
  cursor: pointer;
}

.comment__date {
  display: inline-block;
  font-size: 10px;
  line-height: 1.3;
  color: #7f7f7f;

  &--my {
    margin-top: 7px;
  }
}

.comment__name {
  display: inline;
  padding-right: 10px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
}

.comment__text {
  margin-top: 3px;
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  white-space: pre-wrap;
}
