.message-wrapper {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;
}

.message-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;

  &--rounded {
    border-radius: 50%;
  }
}

.message {
  position: relative;
  word-break: break-word;
  display: grid;
}

.message__date {
  display: inline-block;
  font-size: 10px;
  line-height: 2.1;
  color: #7f7f7f;
}

.message__name {
  display: inline-block;
  padding-right: 10px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
}

.message__text {
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  white-space: pre-wrap;
}

.message__attached-images {
  background-color: transparent;
  width: 100%;
  max-width: unset;
  justify-self: start;

  @media (min-width: 576px) {
    max-width: 250px;
  }
}

.message__attached-images-wrapper {
  gap: 10px;
  justify-content: start;
}

.message__attached-image-wrapper {
  padding-bottom: 67%;
}
