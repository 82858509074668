.vehicle-grid {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin: auto;
  justify-content: center;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1400px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
