.edit-customer-modal {
  width: 405px;
}

.edit-customer-modal__title-wrapper {
  width: 100%;
  height: 68px;
  padding: 0px 50px;
  background-color: #ffce00;
  display: grid;
  align-content: center;
  border-radius: 5px 5px 0 0;
}

.edit-customer-modal__title {
  font-size: 14px;
  font-weight: bold;
}

.edit-customer-modal__form-wrapper {
  width: 100%;
  padding: 15px 50px 50px 50px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  position: relative;
}

.edit-customer-modal__inputs-wrapper {
  display: grid;
  grid-row-gap: 15px;
}

.edit-customer-modal__input--first-name {
  width: 205px;
}

.edit-customer-modal__input--zip-code {
  width: 307px;
  padding: 0;
  height: 39px;
  background-color: #fff;
  border: unset;
  border-radius: 0;
  border-bottom: solid 1px #f7f7f7;
  font-size: 14px;
  color: #1c1c1c;
  outline: none;

  &::placeholder {
    color: #8d8d8d;
  }

  &--error {
    border-bottom: solid 1px #fd0100;
    color: #fd0100;
  }
}

.edit-customer-modal__input--error {
  position: absolute;
  top: 167px;
  right: 49px;
  font-size: 10px;
  color: #fd0100;
}

.edit-customer-modal__password-link {
  text-transform: uppercase;
  color: #5c57ff;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  justify-self: center;
  user-select: none;
  font-size: 14px;
  position: relative;
  top: 12px;
}

.edit-customer-modal__avatar-wrapper {
  position: absolute;
  top: 25px;
  right: 45px;
  display: grid;
  grid-gap: 5px;
  width: 62px;
  height: fit-content;
  border-radius: 50%;
  justify-items: center;
  cursor: pointer;
  &:hover > .edit-customer-modal__avatar__text {
    display: block;
  }
}

.edit-customer-modal__avatar {
  width: 50px;
  height: 50px;
}

.edit-customer-modal__avatar__text {
  display: none;
  font-size: 8px;
  line-height: 1.25;
  position: relative;
  top: 0;
  right: 2px;
}

.edit-customer-modal__buttons-wrapper {
  margin-top: 38px;
  width: 305px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
}

.edit-customer-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;

  &:disabled {
    background-color: #cfdeff;
  }
}
