.attached_images {
  background-color: transparent;
  width: 75%;
  max-width: 370px;
  justify-self: start;
}

.attached_images__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: auto;
  gap: 5px;
  justify-content: end;

  &--single {
    grid-template-columns: 1fr;
  }
}

.attached_images__image-wrapper {
  position: relative;
  padding-bottom: 75%;
  border-radius: 5px;
  cursor: pointer;
}

.attached_images__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.attached_images__image-image-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
}

.attached_images__hidden-images-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
