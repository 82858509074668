.user-details-modal {
  position: relative;
  width: 405px;
  height: 367px;
}

.user-details-modal__title-wrapper {
  width: 100%;
  height: 68px;
  padding: 0px 50px;
  background-color: #ffce00;
  display: grid;
  align-content: center;
  border-radius: 5px 5px 0 0;
}

.user-details-modal__title {
  font-size: 14px;
  font-weight: bold;
}

.user-details-modal__cross-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.user-details-modal__form-wrapper {
  width: 100%;
  padding: 25px 50px 58px;
  display: grid;
  grid-row-gap: 38px;
  background-color: #fff;
}

.user-details-modal__form-text {
  width: 250px;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.3;
}

.user-details-modal__form-avatar {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 50px;
}

.user-details-modal__form-link {
  display: grid;
  grid-template-columns: auto 50px;
}

.user-details-modal__form-text--title-link {
  color: #36dec5;
}

.user-details-modal__form-text--link {
  margin-top: 10px;
  grid-column: 1;
}

.user-details-modal__copy-icon {
  width: 15px;
  height: 17px;
  margin-top: 6px;
  cursor: pointer;
  justify-self: end;
}
