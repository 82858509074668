.confirm-modal {
  width: 100vw;
  max-width: 343px;
  padding: 24px 25px 50px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}

.confirm-modal__cross {
  margin-left: auto;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.confirm-modal__content {
  margin: 25px 0 0;
  padding: 0 25px;
}

.confirm-modal__text {
  width: 100%;
  padding: 0 2px;
  font-size: 16px;
  line-height: 1.35;
  text-align: center;
}

.confirm-modal__checkbox-wrapper {
  margin: 17px 0 0;
  display: grid;
  grid-template-columns: auto 1fr;
}

.confirm-modal__checkbox {
  margin: 3px 0 0;
  display: grid;
  grid-template-columns: unset;
}

.confirm-modal__checkbox-text {
  padding: 0 14px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: #1c1c1c;
}

.confirm-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-top: 20px;
}
