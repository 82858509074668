.watch-list-vehicle-details__wrapper {
  width: 100%;
  padding-top: 20px;
  display: grid;
  grid-row-gap: 20px;
  max-width: 520px;
  margin: auto;
  @media (min-width: 744px) {
    max-width: 1014px;
  }
  @media (min-width: 1024px) {
    width: 790px;
  }

  @media (min-width: 1280px) {
    width: 100%;
    max-width: 1920px;
  }
}

.watch-list-vehicle-details {
  width: 100%;

  @media (min-width: 744px) {
    padding: 0 112px;
    display: grid;
    grid-template-areas:
      "vehicle-gallery"
      "main-info"
      "description-and-note"
      "vehicle-info";
    grid-row-gap: 20px;
  }

  @media (min-width: 1024px) {
    padding: 0;
    grid-template-areas:
      "vehicle-gallery vehicle-gallery"
      "vehicle-info main-info"
      "vehicle-info description-and-note"
      "vehicle-info .";
    grid-template-columns: 390px 1fr;
    grid-column-gap: 20px;
    align-content: start;
  }
  @media (min-width: 1280px) {
    grid-template-areas:
      "vehicle-gallery main-info"
      "vehicle-gallery vehicle-info"
      "description-and-note vehicle-info"
      ". vehicle-info";
    grid-template-columns: max-content 1fr;
    grid-column-gap: 50px;
    align-content: start;
    justify-content: center;
    padding: 0 110px;
    grid-row-gap: 15px;
  }

  @media (min-width: 1280px) {
    grid-row-gap: 20px;
  }
}

.watch-list-vehicle-details__main-info {
  grid-area: main-info;
}

.watch-list-vehicle-details__vehicle-gallery {
  margin-top: 20px;
  grid-area: vehicle-gallery;

  @media (min-width: 744px) {
    margin-top: 0;
  }

  @media (min-width: 1280px) {
    width: 48.4vw;
    max-width: 790px;
    margin-top: 0;
  }
}

.watch-list-vehicle-details__description-and-note {
  display: grid;
  grid-area: description-and-note;
  grid-row-gap: 17px;
  margin-top: 20px;
  padding: 0 20px;

  @media (min-width: 744px) {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    grid-template-columns: unset;
    grid-row-gap: auto auto;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: -4px;
  }
}

.watch-list-vehicle-details__vehicle-info {
  grid-area: vehicle-info;
}
