.customer-vehicle-info {
  width: 100%;
}

.customer-vehicle-info__overview {
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-row-gap: 8px;
  padding: 24px 25px;

  &:empty {
    padding: 0;
  }

  @media (min-width: 620px) {
    padding: 24px 50px;
  }
}

.customer-vehicle-info__row {
  display: grid;
  grid-template-columns: 115px 1fr;
  grid-column-gap: 10px;

  @media (min-width: 620px) {
    grid-column-gap: 65px;
  }

  @media (min-width: 1280px) {
    grid-column-gap: 20px;
  }
}

.customer-vehicle-info__title {
  font-size: 12px;
  line-height: 1.5;
  word-break: break-word;
}

.customer-vehicle-info__text {
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
}
