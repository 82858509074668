.watch-list-card {
  width: 100%;
  padding: 25px;
  padding-right: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.05);
  text-decoration: none;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 15px;

  &:after {
    content: "";
    position: absolute;
    width: 1100%;
    height: 1100%;
    top: 34px;
    right: -504%;
    background-color: #fff;
    transform-origin: 54% 0;
    transform: rotate(34deg);
    z-index: -1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 41px solid transparent;
    border-left: 62px solid #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    border-top-right-radius: 100%;
    width: 0;
  }

  @media (min-width: 514px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
    padding: 25px 25px 21px;
  }

  @media (min-width: 1280px) {
    width: auto;
    max-width: 300px;
    padding-bottom: 23px;
  }

  @media (min-width: 1920px) {
    max-width: 385px;
  }
}

.watch-list-card__thumbnail,
.watch-list-card__thumbnail-placeholder {
  width: 160px;
  height: 120px;

  @media (min-width: 1280px) {
    width: 100%;
    height: 120px;
  }

  @media (min-width: 1920px) {
    height: 187px;
  }
}

.watch-list-card__thumbnail {
  object-fit: cover;
  display: block;
}

.watch-list-card__thumbnail-placeholder {
  background-color: #f7f7f7;
  background-image: url(../../assets/images/logo-gray.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.watch-list-card__info {
  width: 100%;
  margin-top: 26px;

  @media (min-width: 1280px) {
    margin-top: 24px;
  }
}

.watch-list-card__text {
  line-height: 1.5;
  word-break: break-word;

  &--bold {
    font-weight: bold;
  }

  &--green {
    color: #36dec5;
  }
}
