.saved-search-details__text-wrapper {
  padding: 20px 0 0 30px;
  width: 100%;
  max-width: 385px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 30px;
  margin: auto;
  position: relative;
  word-break: break-word;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 576px) {
    max-width: unset;
    justify-content: center;
    text-align: center;
  }

  @media (min-width: 744px) {
    grid-column-gap: 15px;
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    padding: 20px 30px 0 45px;
  }

  @media (min-width: 1900px) {
    padding: 20px 30px 0;
  }
}

.saved-search-details__text {
  text-decoration: none;
  font-size: 18px;
  line-height: 1.17;

  &--bold {
    font-weight: bold;
  }
}

.saved-search-details__icons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 20px;
  margin-top: -2px;

  @media (min-width: 744px) {
    align-self: center;
    grid-column-gap: 15px;
    margin-top: 0;
  }
}

.saved-search-details__trash-icon,
.saved-search-details__rename-icon {
  height: 26px;
  cursor: pointer;
  @media (min-width: 744px) {
    height: 24px;
  }
}
