.vehicle-valuation-wrapper {
  display: grid;
  grid-row-gap: 10px;
  @media (min-width: 620px) {
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 50px;
    align-items: center;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
  }
}

.vehicle-valuation-wrapper__text {
  margin-left: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
  @media (min-width: 620px) {
    margin-left: 15px;
    margin-bottom: 0;
  }
}

.vehicle-valuation-wrapper__btns {
  display: grid;
  grid-template-columns: 50px 50px;
  grid-gap: 50px;
  justify-self: center;
}
