.footer {
  background-color: #1c1c1c;
  display: grid;
  justify-content: center;
}

.footer__wrapper {
  padding: 50px 0;

  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: 301px 180px;
    column-gap: 40px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 301px 393px;
    column-gap: 98px;
    padding: 100px 0;
  }

  @media (min-width: 1200px) {
    column-gap: 368px;
  }

  .footer__info-wrapper {
    margin: 0 auto;

    .footer__logo-wrapper {
      img {
        width: 301px;
        display: block;
      }

      label {
        color: #8d8d8d;
        display: block;
        padding-top: 7px;
        font-size: 18px;
        line-height: 32px;
      }
    }

    .footer__contacts-wrapper {
      margin-top: 25px;

      @media (min-width: 576px) {
        margin-top: 50px;
      }

      @media (min-width: 992px) {
        // Changed value from 44px (Zeplin) to 40px so that
        // lower border is aligned with Useful Links block
        margin-top: 40px;
      }

      @media (min-width: 1200px) {
        // Changed value from 20px (Zeplin) to 15px so that
        // lower border is aligned with Useful Links block
        margin-top: 15px;
      }

      a {
        color: #fff;
        font-size: 18px;
        line-height: 27px;
        text-decoration: none;
        display: block;
      }

      label {
        color: #fff;
        font-size: 18px;
        line-height: 27px;
        display: block;
      }
    }
  }

  .footer__useful-links-wrapper {
    margin: 25px auto 0;

    @media (min-width: 576px) {
      margin-top: 0px;
    }

    .footer__links-title {
      label {
        font-size: 18px;
        line-height: 32px;
        color: #8d8d8d;

        @media (min-width: 576px) {
          font-size: 25px;
        }
      }
    }

    .footer__links-wrapper {
      display: grid;
      grid-template-columns: 126px 126px;
      column-gap: 49px;

      @media (min-width: 576px) {
        display: block;
        margin-top: 25px;
      }

      @media (min-width: 992px) {
        display: grid;
        grid-template-columns: 163px 180px;
        column-gap: 50px;
      }

      a {
        color: #8d8d8d;
        font-size: 14px;
        line-height: 48px;
        text-decoration: underline;
        text-decoration-color: #8d8d8d;
        display: block;

        &:hover {
          color: #5c57ff;
          text-decoration-color: #5c57ff;
        }

        @media (min-width: 576px) {
          font-size: 20px;
          line-height: 40px;
        }

        @media (min-width: 1200px) {
          line-height: 32px;
        }
      }
    }
  }
}
