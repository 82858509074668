.unassign-customer-modal {
  width: 343px;
  padding: 66px 50px 50px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;

  @media (min-width: 1200px) {
    margin-bottom: 66px;
  }
}

.unassign-customer-modal__cross {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 41px;
  right: 41px;

  @media (min-width: 576px) {
    top: 25px;
    right: 25px;
  }
}

.unassign-customer-modal__message {
  font-size: 16px;
  line-height: 1.31;
  text-align: center;

  &--bold {
    font-weight: bold;
  }
}

.unassign-customer-modal__button-wrapper {
  margin-top: 50px;
}

.unassign-customer-modal__button {
  width: 243px;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;
}
