.vehicle-controllers-wrapper {
  padding-top: 20px;
  padding-left: 30px;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;

  @media (min-width: 1200px) {
    padding-top: 22px;
  }
}

.vehicle-checkbox-wrapper {
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
}

.vehicle-checkbox-checkmark {
  border-color: #5d57f9;
}

.vehicle-checkbox-label {
  grid-template-columns: 1fr;

  @media (min-width: 576px) {
    grid-template-columns: auto auto;
  }
}

.vehicle-checkbox-title {
  font-size: 14px;
  line-height: 1.5;
  text-decoration: underline;
  text-align: left;

  @media (min-width: 576px) {
    letter-spacing: 0.7px;
    line-height: 1;
    text-decoration: unset;
  }
}

.vehicle-controllers-control {
  position: relative;
  padding: 0 15px;
  display: grid;
  align-content: center;

  @media (min-width: 1200px) {
    padding: 0 21px;
  }

  &:first-of-type {
    padding-left: 0;

    &::before {
      content: none;
    }
  }

  &:last-of-type {
    padding-right: 0;
  }

  &::before {
    content: none;

    @media (min-width: 576px) {
      content: "";
      position: absolute;
      left: 0;
      display: inline-block;
      width: 1px;
      height: 100%;
      background-color: #cfdeff;
    }
  }

  &--view-toggle {
    display: none;

    @media (min-width: 1200px) {
      display: grid;
    }
  }

  &--save-search {
    max-width: 80px;

    @media (min-width: 576px) {
      max-width: unset;
    }
  }

  &--select-all {
    max-width: 65px;

    @media (min-width: 576px) {
      max-width: unset;
    }
  }

  &--export-csv {
    max-width: 75px;

    @media (min-width: 360px) {
      max-width: 130px;
    }

    @media (min-width: 576px) {
      max-width: unset;
    }
  }
}

.export-csv__btn__text {
  display: inline-block;
  color: #3f3cca;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: inherit;
  text-transform: inherit;

  @media (min-width: 576px) {
    letter-spacing: 0.7px;
    line-height: 1;
  }
}
