.custom-price {
  width: 218px;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 192px auto;

  @media (min-width: 1024px) {
    width: 210px;
    grid-template-columns: 185px auto;
  }
}

.custom-price__textarea {
  width: 100%;
  height: 30px;
  padding: 0;
  outline: 0;
  border: none;
  background-color: unset;
  font-size: 20px;
  line-height: 1.5;
  font-style: italic;
  color: #1c1c1c;
  word-break: break-word;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: #1c1c1c;
  }

  &--delisted {
    color: #8a8a8a;

    &::placeholder {
      color: #8a8a8a;
    }
  }
}

.custom-price__icons {
  margin-top: 2px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-self: end;
  justify-content: end;

  @media (min-width: 1280px) {
    margin-top: 7px;
  }
}

.custom-price__icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-right: 10px;

  @media (min-width: 1280px) {
    width: 16px;
    height: 16px;
  }

  &--save {
    margin-right: 0;
  }

  &--disabled {
    cursor: not-allowed;
  }
}
