.vehicle-price {
  width: 100%;
  max-width: 620px;
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 620px) {
    grid-template-columns: max-content 1fr;
    grid-column-gap: 15px;
    justify-items: start;
  }
}

.vehicle-price__price {
  font-size: 30px;
  line-height: 1.33;
  color: #36dec5;
  text-align: center;

  &--delisted {
    opacity: 0.5;
  }

  @media (min-width: 620px) {
    text-align: unset;
  }
}

.vehicle-price__text {
  width: 100%;
  font-size: 10px;
  line-height: 1.44;
  color: #7f7f7f;
  padding: 0 25px;
  text-align: center;

  &--delisted {
    opacity: 0.5;
  }

  @media (min-width: 620px) {
    padding: 0;
    text-align: unset;
  }
}

.vehicle-price__br {
  display: none;

  @media (min-width: 620px) {
    display: block;
  }
}
