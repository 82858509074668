.customer-details-modal {
  position: relative;
  width: 405px;
  height: 367px;
  border-radius: 5px;
}

.customer-details-modal__title-wrapper {
  width: 100%;
  height: 68px;
  padding: 0px 50px;
  background-color: #ffce00;
  display: grid;
  align-content: center;
  border-radius: 5px 5px 0 0;
}

.customer-details-modal__title {
  font-size: 14px;
  font-weight: bold;
}

.customer-details-modal__cross-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.customer-details-modal__form-wrapper {
  width: 100%;
  border-radius: 0 0 5px 5px;
  padding: 25px 50px 50px;
  display: grid;
  grid-row-gap: 35px;
  background-color: #fff;
}

.customer-details-modal__form-avatar {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 50px;
}

.customer-details-modal__form-text {
  width: 280px;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.3;
}
