.convos-toolbar-wrapper {
  margin: 22px 16px 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  column-gap: 20px;

  @media (min-width: 690px) {
    margin: 22px 0 0;
    column-gap: 0;
    align-items: end;
  }
}

.convos-toolbar__checkbox {
  align-items: start;

  @media (min-width: 690px) {
    align-items: center;
  }
}

.convos-checkbox-checkmark {
  margin: 2px 0 0;
  border-color: #5d57f9;

  @media (min-width: 690px) {
    margin: 0;
  }
}

.convos-checkbox-title {
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  text-decoration: underline;

  @media (min-width: 690px) {
    letter-spacing: 0.7px;
    line-height: 1;
    text-decoration: none;
  }
}

.convos-checkbox-wrapper {
  display: none;

  @media (min-width: 690px) {
    display: block;
  }
}

.convos-checkbox-label {
  grid-template-columns: 1fr;

  @media (min-width: 690px) {
    grid-template-columns: auto auto;
  }
}

.convos-toolbar-control {
  position: relative;
  display: grid;
  align-content: center;

  @media (min-width: 690px) {
    padding: 0 20px;
  }

  &:first-of-type {
    padding-left: 0;

    &::before {
      content: none;
    }
  }

  &:last-of-type {
    padding-right: 0;
  }

  &::before {
    content: none;

    @media (min-width: 690px) {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 1px;
      height: 18px;
      background-color: #cfdeff;
    }
  }

  &--answered-only {
    max-width: 74px;

    @media (min-width: 690px) {
      max-width: unset;
    }
  }

  &--export-csv {
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }
}

.convos-toolbar__is-responded-checkbox {
  align-items: start;

  @media (min-width: 690px) {
    align-items: center;
  }
}

.export-csv__btn__text {
  display: inline-block;
  color: #3f3cca;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: inherit;
  text-transform: inherit;

  @media (min-width: 690px) {
    letter-spacing: 0.7px;
    line-height: 1.3;
  }
}
