.public-watch-list-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  padding: 0 20px;
  width: fit-content;
  margin: auto;

  @media (min-width: 560px) {
    grid-template-columns: repeat(2, 250px);
    padding: 0;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 250px);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 250px);
  }
}
