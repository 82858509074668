.vehicles {
  width: 100%;
  max-width: 2305px;
  padding: 0 20px;
  margin: auto;

  @media (min-width: 1200px) {
    padding: 0 20px 0 0;
  }
}

.add-to-watch-list-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: unset;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &--visible {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }

  @media (min-width: 576px) {
    top: 50%;
    right: 40px;
    bottom: unset;
    left: unset;
    transform: translate(0, -50%);
  }
}

.add-to-watch-list-button-background {
  fill: #ffce00;
}

.add-to-watch-list-button-content {
  fill: #1c1c1c;
}

.add-to-watch-list-button:hover {
  box-shadow: 0 0 12px 0 #ffce00;
}

.add-to-watch-list-button:active {
  box-shadow: unset;

  .add-to-watch-list-button-background {
    fill: #f4b830;
  }
}
