.vehicles {
  width: 100%;
  max-width: 2305px;
  padding: 0 20px;
  margin: auto;
  margin-bottom: 50px;

  @media (min-width: 1200px) {
    padding: 0 20px 0 0;
  }
}
