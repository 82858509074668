.upload-photo__button {
  width: 120px;
  height: 34px;
  border-radius: 30px;
  border: solid 1px #5c57ff;
  background-color: #fff;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #5c57ff;
  cursor: pointer;
}
