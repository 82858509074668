.unassigned {
}

.unassigned__table {
  display: grid;
  grid-row-gap: 7px;
  padding: 0 10px;

  @media (min-width: 744px) {
    grid-row-gap: 1px;
  }

  @media (min-width: 1024px) {
    padding: 0 20px;
  }
}

.unassigned__heading {
  display: none;
  padding: 29px 10px;
  background-color: #fff;
  grid-column-gap: 5px;
  grid-template-columns: 215fr 225fr 115fr 134px;
  border-radius: 10px 10px 0 0;

  @media (min-width: 744px) {
    display: grid;
    margin-bottom: 2px;
  }

  @media (min-width: 1024px) {
    grid-column-gap: 20px;
    padding: 18px 30px 17px 30px;
    grid-template-columns: 245fr 300fr 185fr 134px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 365fr 391fr 230fr 134px;
  }
}

.unassigned__title {
  font-size: 16px;
  font-weight: 500;
  color: #7f7f7f;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
}

.unassigned__row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 134px;
  grid-row-gap: 18px;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;

  @media (min-width: 744px) {
    grid-template-columns: 200fr 210fr 99fr 134px;
    grid-column-gap: 20px;
    grid-row-gap: 0;
    border-radius: 0;
    padding: 20px 10px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 245fr 300fr 185fr 134px;
    padding: 8px 30px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 365fr 391fr 230fr 134px;
  }
}

.unassigned__user {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 10px;
  align-items: center;
}

.unassigned__avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.unassigned__text {
  font-size: 16px;
  color: #1c1c1c;
  word-break: break-word;

  &--date {
    font-size: 10px;
    color: #7f7f7f;
    align-self: center;
    justify-self: end;
  }

  @media (min-width: 744px) {
    &--date {
      font-size: 16px;
      color: #1c1c1c;
      justify-self: start;
      grid-row: 1;
      grid-column: 3;
    }

    &--email {
      align-self: center;
      grid-row: 1;
      grid-column: 2;
    }
  }
}

.unassigned__button {
  width: 100%;
  height: 34px;
  background-color: #1c1c1c;
  font-size: 14px;
  letter-spacing: 0.7px;

  &:hover {
    box-shadow: unset;
  }

  &:active {
    box-shadow: unset;
    background-color: #1c1c1c;
    color: #fff;
  }

  @media (min-width: 744px) {
    grid-row: 1;
    grid-column: 4;
    align-self: center;
  }
}

.unassigned__pagination {
  padding-top: 6px;

  @media (min-width: 744px) {
    padding-top: 4px;
  }
}

.unassigned__scroll-to-top-arrow {
  width: 46px;
  height: 46px;
  transition: all 0.2s ease-in-out;
  position: fixed;
  right: 40px;
  bottom: 20px;
  opacity: 0;
  border-radius: 50%;

  &--open {
    cursor: pointer;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
  }

  &:active {
    box-shadow: unset;
  }
}

.unassigned__empty-wrapper {
  width: 100%;
  margin-top: 100px;
  padding: 0 20px;
}

.unassigned__banner {
  font-size: 40px;
  font-weight: normal;
  color: #1c1c1c;
  letter-spacing: -1px;
  width: 100%;
  text-align: center;
}

.unassigned__logo {
  display: block;
  margin: 32px auto;
  width: 100%;
  max-width: 400px;
}
