.watch-lists-details__icon {
  margin-left: 4px;
  cursor: pointer;
  align-self: center;
}

.watch-lists-details__text-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  gap: 20px;
  justify-content: space-between;
  position: relative;
  word-break: break-word;
  justify-content: space-between;

  @media (min-width: 744px) {
    column-gap: 15px;
    row-gap: 20px;
    justify-content: start;
  }

  @media (min-width: 1280px) {
    grid-template-columns: auto auto 1fr;
    grid-template-rows: unset;
  }

  &--vehicle {
    margin: 0;
    padding: 2px 20px 0;
    grid-template-columns: unset;
    grid-template-rows: unset;
    justify-content: start;

    @media (min-width: 744px) {
      justify-content: center;
      margin: auto;
    }
  }
}

.watch-lists-details__text {
  text-decoration: none;
  font-size: 18px;
  line-height: 1.17;

  &--bold {
    font-weight: bold;
  }

  &--vehicle {
    @media (min-width: 744px) {
      font-size: 24px;
    }
  }
}

.watch-lists-details__link {
  cursor: default;
  align-self: center;
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  &--cursor {
    cursor: pointer;
  }
}

.watch-lists-details__icons {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 20px;
  margin-top: -2px;

  @media (min-width: 744px) {
    align-self: center;
    grid-column-gap: 15px;
    margin-top: 0;
  }
}

.watch-lists-details__trash-icon,
.watch-lists-details__rename-icon,
.watch-lists-details__copy-icon {
  height: 26px;
  cursor: pointer;
  @media (min-width: 744px) {
    height: 24px;
  }
}

.watch-lists-details__buttons {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  justify-self: start;
  display: grid;
  row-gap: 20px;

  @media (min-width: 744px) {
    grid-template-columns: repeat(2, auto);
    column-gap: 10px;
    row-gap: unset;
  }

  @media (min-width: 1280px) {
    grid-column: 3 / 4;
    grid-row: unset;
    justify-self: end;
  }
}

.watch-lists-details__button {
  min-width: 186px;
  width: auto;
  height: auto;
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.7px;
  transition: 0.2s ease-in-out;
}

.watch-lists-details__button--start-autoconvo {
  color: #1c1c1c;
  box-shadow: unset;
  background-color: #ffce00;

  &:hover {
    color: #1c1c1c;
    box-shadow: 0 0 10px 0 #dec36f;
  }

  &:active {
    color: #1c1c1c;
    box-shadow: unset;
    background-color: #f4b800;
  }

  &:disabled {
    color: #1c1c1c;
    box-shadow: unset;
    background-color: #fbe27b;
  }
}

.watch-lists-details__button--stop-autoconvo {
  color: #ffffff;
  box-shadow: unset;
  background-color: #1c1c1c;

  &:hover {
    color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
  }

  &:active {
    color: #7f7f7f;
    box-shadow: unset;
    background-color: #000000;
  }

  &:disabled {
    color: #f9f9f9;
    box-shadow: unset;
    background-color: #7f7f7f;
  }
}
