.watch-lists-details {
  width: 100%;
  max-width: 1640px;
  margin: auto;
  padding: 20px 20px 0;
  display: grid;
  grid-row-gap: 20px;

  @media (min-width: 1200px) {
    padding: 15px 110px 0;
    grid-row-gap: 15px;
  }

  @media (min-width: 1920px) {
    max-width: 2045px;
    padding: 20px 170px 0;
    grid-row-gap: 20px;
  }
}
