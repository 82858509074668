.checkbox {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 10px;
  cursor: pointer;
}

.checkbox__text {
  font-size: 14px;
  line-height: 18px;

  &--bold {
    font-weight: bold;
  }
}

.checkbox__icon {
  width: 16px;
}
