@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Work+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  color: #1c1c1c;
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
}

#app-root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}
