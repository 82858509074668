.save-search {
  display: inline-block;
}

.save-search__btn {
  display: block;
  width: fit-content;
  margin: 0 0 0 auto;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: left;
  outline: none;
  border: none;
  background-color: transparent;
  color: #3f3cca;
  cursor: pointer;

  @media (min-width: 576px) {
    letter-spacing: 0.7px;
    line-height: 1;
  }
}
