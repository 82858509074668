.simple-input {
  width: 305px;
  height: 40px;
  position: relative;
}

.simple-input__input {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: unset;
  border-bottom: solid 1px #f7f7f7;
  font-size: 14px;
  color: #1c1c1c;
  outline: none;
  border-radius: 0;

  &::placeholder {
    color: #8d8d8d;
  }

  &--error {
    border-bottom: solid 1px #fd0100;
    color: #fd0100;
  }
}

.simple-input__input {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-bottom: solid 1px #f7f7f7;
  font-size: 14px;
  color: #1c1c1c;
  outline: none;

  &::placeholder {
    color: #8d8d8d;
  }

  &--error {
    border-bottom: solid 1px #fd0100;
    color: #fd0100;
  }
}

.simple-input__error {
  font-size: 10px;
  color: #fd0100;
  position: absolute;
  top: calc(100% + 7px);
  right: 0;
  z-index: 1;
}
