.delete-modal {
  width: 100vw;
  max-width: 343px;
  padding: 66px 50px 50px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}

.delete-modal__cross {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.delete-modal__text {
  font-size: 16px;
  line-height: 1.35;
  text-align: center;

  &--bold {
    font-weight: bold;
  }
}

.delete-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-top: 50px;
}
