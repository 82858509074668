.other-conversation {
  width: 100%;
  padding: 21px 5px 10px 10px;
  position: relative;
  display: grid;
  grid-row-gap: 9px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(185, 182, 182, 0.8);
    z-index: 3;
  }

  @media (min-width: 576px) {
    padding: 14px 10px 13px;
    grid-template-columns: 73px 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 22px;
    align-items: center;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 277fr 507fr 91px;
    grid-column-gap: 20px;
    grid-row-gap: 22px;
    padding: 14px 30px 13px;
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }

  @media (min-width: 1280px) {
    grid-template-columns: 325fr 632fr 167fr;
    padding: 11px 30px 10px;
  }
}

.other-conversation__value-date {
  position: absolute;
  top: 7px;
  right: 5px;
  font-size: 10px;
  color: #7f7f7f;

  @media (min-width: 576px) {
    display: none;
  }
}

.other-conversation__label {
  display: none;

  @media (min-width: 576px) {
    display: block;
    line-height: 1.5;
    color: #7f7f7f;
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.other-conversation__value {
  font-size: 16px;
  line-height: 1.5;
}

.other-conversation__sales-rep {
  @media (min-width: 1280px) {
    align-self: unset;
  }
}

.other-conversation__last-message-wrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
}

.other-conversation__img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: cover;

  &--rounded {
    border-radius: 50%;
  }
}

.other-conversation__last-message {
  line-height: 1.5;
  font-style: italic;
  padding-right: 13px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 576px) {
    font-style: unset;
    padding-right: 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset;
  }

  @media (min-width: 1280px) {
    font-style: italic;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.other-conversation__value-last-image {
  width: 120px;
  height: 80px;
  border-radius: 5px;
}

.other-conversation__message-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.other-conversation__datetime {
  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  @media (min-width: 1024px) {
    text-align: right;
  }
}
