.comment-wrapper {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.comment {
  position: relative;
}

.comment__textarea {
  width: 100%;
  height: 40px;
  padding: 11px 40px 11px 15px;
  outline: 0;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  color: #1c1c1c;
  word-break: break-word;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: #878787;
  }
}

.comment__icon {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }
}
