.note {
  position: relative;
}

.note__textarea {
  width: 100%;
  min-height: 82px;
  padding: 14px 40px 14px 14px;
  outline: 0;
  border: none;
  border-radius: 5px;
  background-color: #cfdeff;
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  color: #3f3cca;
  word-break: break-word;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: #84abff;
  }
}

.note__icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: grid;
  grid-row-gap: 10px;
}

.note__icon {
  width: 26px;
  height: 26px;
  cursor: pointer;

  @media (min-width: 1280px) {
    width: 16px;
    height: 16px;
  }

  &--disabled {
    cursor: not-allowed;
  }
}
