.my-convos {
  width: 100%;
  padding: 23px 10px 0;

  @media (min-width: 1024px) {
    padding: 23px 20px 0;
  }
}

.my-convos__title {
  width: max-content;
  margin: auto;
  font-size: 20px;
  font-weight: bold;
}

.my-convos__labels {
  display: none;

  @media (min-width: 1024px) {
    width: 100%;
    min-height: 54px;
    padding: 14px 30px 13px;
    grid-template-columns: 16px 140fr 265fr 277fr 180px;
    grid-column-gap: 20px;
    text-decoration: none;
    background-color: #fff;
    display: grid;
    align-items: center;
    border-radius: 10px 10px 0 0;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 16px 210fr 287fr 453fr 173fr;
  }
}

.my-convos__label {
  font-size: 18px;
  font-weight: 500;
  color: #7f7f7f;

  &--date {
    text-align: right;
  }
}

.my-convos__table {
  width: 100%;
  margin-top: 13px;
  display: grid;
  grid-row-gap: 5px;

  @media (min-width: 576px) {
    grid-row-gap: 10px;
  }

  @media (min-width: 744px) {
    margin-top: 15px;
  }

  @media (min-width: 1024px) {
    grid-row-gap: 2px;
  }
}

.my-convos__empty-message-list__title {
  font-size: 20px;
  text-align: center;
  margin-top: 50px;

  @media (min-width: 1024px) {
    font-size: 40px;
    margin-top: 114px;
  }
}

.my-convos__icon-autoagents {
  width: 400px;
  max-width: 90vw;
  width: 32vw;
  max-width: 400px;
  display: block;
  margin: auto;
  margin-top: 25px;

  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
}

.my-convos__scroll-to-top-arrow {
  width: 46px;
  height: 46px;
  transition: all 0.2s ease-in-out;
  position: fixed;
  right: 40px;
  bottom: 20px;
  opacity: 0;
  border-radius: 50%;

  &--open {
    cursor: pointer;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
  }

  &:active {
    box-shadow: unset;
  }
}
