.vehicle-gallery {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.vehicle-gallery__photos-wrapper {
  width: 100%;
  position: relative;
}

.vehicle-gallery__placeholder {
  position: absolute;
  display: inline-grid;
  place-items: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.vehicle-gallery__logo {
  width: 45%;
}

.vehicle-gallery__photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  transition: all 400ms ease-in-out;
}

.vehicle-gallery__delisted {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  color: #fd0100;
  cursor: default;
}

.vehicle-gallery__wip-badge-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.vehicle-gallery__wip-badge {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vehicle-gallery__reaction-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: default;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.vehicle-gallery__arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  cursor: pointer;
  @media (min-width: 744px) {
    display: block;
  }
  @media (min-width: 1280px) {
    display: block;
  }

  &--prev {
    left: 15px;
  }

  &--next {
    right: 15px;
  }
}

.vehicle-gallery__thumbnails-wrapper {
  width: 100%;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
}

.vehicle-gallery__thumbnail {
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: all 400ms ease-in-out;
  cursor: pointer;
}

.vehicle-gallery__thumbnail-cover {
  position: absolute;
  top: 0;
  transition: all 400ms ease-in-out;
  background-color: #fff;
  opacity: 0.7;
}
