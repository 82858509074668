.delete-user-modal {
  width: 405px;
}

.delete-user-modal__title-wrapper {
  width: 100%;
  height: 68px;
  padding: 0px 50px;
  background-color: #ffce00;
  display: grid;
  align-content: center;
  border-radius: 5px 5px 0 0;
}

.delete-user-modal__title {
  font-size: 14px;
  font-weight: bold;
}

.delete-user-modal__body-wrapper {
  width: 100%;
  padding: 25px 50px 50px 50px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  position: relative;
}

.delete-user-modal__user-wrapper {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-column-gap: 25px;
}

.delete-user-modal__message {
  font-size: 14px;
  align-self: center;
  line-height: 1.72;

  &--bold {
    font-weight: bold;
    word-break: break-word;
  }
}

.delete-user-modal__avatar {
  width: 50px;
  height: 50px;
}

.delete-user-modal__buttons-wrapper {
  margin-top: 46px;
  width: 305px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
}

.delete-user-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;

  &--remove-user {
    &:disabled {
      background-color: #cfdeff;
    }
  }
}
