.vehicle-view-toggle {
  display: none;

  @media (min-width: 1200px) {
    display: inline-block;
  }
}

.vehicle-view-controls-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  justify-content: start;
  align-items: center;
}

.vehicle-view-toggle__btn {
  border: none;
  outline: none;
  background-color: transparent;
  height: 11px;
  cursor: pointer;

  &--car {
    width: 16px;
  }

  &--list {
    width: 18px;
  }
}

.vehicle-view-toggle__grid-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
