.convo-images-modal {
  position: relative;
  width: calc(100vw - 80px);
  max-width: 520px;

  @media (min-width: 744px) {
    width: calc(100vw - 224px);
    max-width: 790px;
  }
}

.convo-images-modal__cross {
  position: absolute;
  top: -26px;
  right: -26px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
