.password-input {
  position: relative;
  width: 100%;
  height: max-content;
}

.password-input__input {
  padding-right: 47px;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}

.password-input__eye {
  position: absolute;
  right: 14px;
  cursor: pointer;
  top: 50%;
  transform: translateY(calc(-50% + 1px));
}
