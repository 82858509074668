.hotlist__reload-btn {
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: 46px;
  height: 46px;
  background-color: transparent;
}

.hotlist__reload-icon {
  position: absolute;
  top: 0;
  left: -15px;
}
