.add-to-watch-list-button {
  height: 46px;
  width: 46px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
  }
}
