.event-wrapper {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;
}

.event-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.event {
  display: grid;
}

.event__text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1c1c;
  word-break: break-word;
}

.event__name,
.event__seller-phone-number {
  font-weight: bold;
}

.event__date {
  font-size: 10px;
  line-height: 1.3;
  color: #7f7f7f;
}
