$toggle-animation-diration: 200ms;

:export {
  toggle-animation-diration: $toggle-animation-diration;
}

.employee-header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all $toggle-animation-diration ease-in-out;
  background-color: #5c57ff;
}

.employee-header__wrapper-content {
  width: 100%;
  height: 100%;
  padding: 13px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  align-items: center;
  align-content: center;
}

.employee-header__left-side {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 19px;
  align-items: center;
}

.employee-header__logo-link {
  height: 34px;
}

.employee-header__logo {
  display: none;
  @media (min-width: 992px) {
    display: block;
    width: 200px;
    height: 34px;
  }
}

.employee-header__logo-mobile {
  width: 34px;
  height: 34px;
  @media (min-width: 992px) {
    display: none;
  }
}

.employee-header__prev-page {
  width: 16px;
  height: 29px;
}

.employee-header__prev-page__logo {
  width: 16px;
  height: 29px;
}

.employee-header__right-side {
  padding-top: 2px;
  display: grid;
  grid-template-columns: repeat(6, max-content);
  grid-column-gap: 20px;
  justify-self: end;
  align-items: center;
  align-content: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(7, max-content);
    grid-column-gap: 16px;
  }
}

.employee-header__my-assigned-customers-icon {
  width: 26px;
  height: 26px;
}

.employee-header__my-saved-searches-icon {
  width: 26px;
  height: 26px;
}

.employee-header__my-convos-icon {
  width: 26px;
  height: 26px;
}

.employee-header__hotlist-icon {
  width: 26px;
  height: 26px;
}

.employee-header__watch-lists-icon {
  width: 26px;
  height: 26px;
}

.employee-header__greeting {
  display: none;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding-bottom: 2px;
  color: #fff;

  @media (min-width: 768px) {
    display: block;
  }
}

.employee-header__logout-link {
  width: 100%;
  height: 100%;
}

.employee-header__sign-out-icon {
  width: 18px;
  height: 26px;
}
