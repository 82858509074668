.hotlist {
  width: 100%;
  max-width: 2305px;
  padding: 0 20px;
  margin: auto;
}

.hotlist__title-wrapper {
  width: 100%;
  max-width: 385px;
  margin: 0 auto;
  padding-top: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  @media (min-width: 576px) {
    max-width: unset;
  }

  @media (min-width: 1200px) {
    padding-left: 20px;
  }
}

.hotlist__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  padding-right: 46px;

  @media (min-width: 1200px) {
    padding-left: 54px;
    padding-right: 46px;
  }
}
