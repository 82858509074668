.vehicles-not-found-placeholder {
  display: grid;
  justify-items: center;
  grid-row-gap: 47px;
  padding-top: 72px;
}

.vehicles-not-found-placeholder__title {
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: -1px;
}

.vehicles-not-found-placeholder__image {
  width: 100%;
  max-width: 400px;
}
